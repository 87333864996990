import { createAction } from '@reduxjs/toolkit'
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects'

import { selectDrawableLocations } from '../effects/deleteTemporaryDrawableGroups'
import { addNewDrawableLocationToStore, generateMeasurementsToUpdate } from './createDrawableLocation'
import createPathItemFromType from './createPathItemFromType'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { selectPastedMaterialAction } from './selectPastedMaterial'
import { UPDATE_OPENING_GROUPS_SUCCESS, updateOpeningGroupsSuccess } from '../../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../../api/api-helper'
import {
    addOpeningLocation,
    bulkCreateOpenings,
    createOpening,
    createOpeningGroupMaterial,
    CreateOpeningResponse,
} from '../../../api/projects-api'
import { updateOpeningGroup } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { GeneralDrawableSettings } from '../../../models/activeDrawableSettings'
import { ActiveFloor } from '../../../models/activeFloor'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { determineDefaultBundleForNewGroup } from '../../../shared/services/drawable-type-service'
import { convertScaleFactorLabelEnumToDecimal } from '../../../utils/calculations/scaleConversion/scaleConversion'
import { getUnitOfMeasureByDrawingType } from '../../../utils/general'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Select, Workspace } from '../../lib/toolBoxes/2D'
import { copyDrawables } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { selectDocumentMappingByActiveFloorId, selectDrawableActiveFloor } from '../../slices/documents'
import {
    ActiveGeometryGroup,
    createBlankGroup,
    GeometricDrawable,
    GeometryGroup,
    selectAllDrawableGroupsGeometries,
    selectDrawableGroupsGeometriesHash,
} from '../../slices/geometry'
import { selectProjectId } from '../../slices/project'
import {
    BulkOpeningCreationData,
    ERROR_PASTING_MATERIAL_MESSAGE,
    IMUP2DDrawableLocation,
    PASTING_MATERIAL_MESSAGE,
    TOOLBAR_MESSAGE_TIMER,
} from '../../types'

export const pasteMaterialAction = createAction<number[]>('pasteMaterial')

export const TEMPORARY_DRAWABLE_ID = -1

function calculateGroupNameFromSettings(regionOrMappingName: string) {
    const location = regionOrMappingName
        ?.replace('FLOOR', '')
        .split(' ')
        .filter((s) => s !== '')
        .join(' ')

    return `${location} FLOOR AREA`.trim()
}

export function* createOrReturnCurrentFloorArea(
    groupToCopy: GeometryGroup,
    projectId: number,
    activeFloor: ActiveFloor,
    regionId: number | null
) {
    const chunkGroups: GeometryGroup[] = yield select(selectAllDrawableGroupsGeometries)
    const activeMapping: DocumentMapping | null = yield select(selectDocumentMappingByActiveFloorId)
    const activeChunk: DocumentChunk = activeFloor.document_chunk

    const potentialFloorAreaGroup: GeometryGroup | undefined = chunkGroups.find((geoGroup) =>
        !regionId
            ? geoGroup.settings.name === calculateGroupNameFromSettings(activeMapping?.page_name ?? '')
            : geoGroup.settings.name ===
              calculateGroupNameFromSettings(activeChunk?.regions.find((region) => region.id === regionId)?.name ?? '')
    )

    if (potentialFloorAreaGroup) return potentialFloorAreaGroup

    const newDrawableGroup: OpeningGroupAPI = yield call(
        createOpeningGroupMaterial,
        projectId,
        undefined,
        {
            ...groupToCopy.settings,
            name: `temporary_${groupToCopy.type}_group`,
            rcm_building_id: groupToCopy.settings.rcm_id,
            bundle_name: determineDefaultBundleForNewGroup(groupToCopy.type),
        },
        groupToCopy.type
    )

    const drawableGroup = {
        ...newDrawableGroup,
        openings: [] as GeometricDrawable[],
        drawables: [] as GeometricDrawable[],
        drawablesPerTab: [] as GeometricDrawable[],
        isActive: {},
    }

    yield put(createBlankGroup(drawableGroup))

    return drawableGroup
}

export function* createFloorAreaGroupsAndOpenings({
    group,
    locations,
    projectId,
    activeFloor,
}: {
    group: GeometryGroup
    locations: BulkOpeningCreationData[]
    projectId: number
    activeFloor: ActiveFloor
}) {
    try {
        let drawableGroup: GeometryGroup = yield createOrReturnCurrentFloorArea(
            group,
            projectId,
            activeFloor,
            locations[0].region_id
        )

        for (const location of locations) {
            const newGroup = yield createOpeningForGroup({
                location,
                projectId,
                newDrawableGroup: drawableGroup,
                activeFloor,
            })

            drawableGroup = newGroup
        }

        const req = {
            openings: drawableGroup.openings.map((opening) => ({ ...opening, outputs: {} })),
            settings: drawableGroup.settings,
            type: drawableGroup.type,
            configuration: drawableGroup.configuration,
            remarks: drawableGroup.remarks,
        }

        const res = yield call(updateOpeningGroup, drawableGroup.project_id, req, String(drawableGroup.id))

        const formattedResponse: UpdateOpeningGroupApiResponse = {
            newGroup: res,
            originalGroup: {
                id: drawableGroup.id,
                project_id: drawableGroup.project_id,
                color: null,
                ...req,
            },
        }

        yield put(updateOpeningGroupsSuccess(formattedResponse))

        return formattedResponse
    } catch (e) {
        console.error(`Failed to create floor area group ${group.id} ${e}`)
    }
}

function* createOpeningForGroup({
    location,
    newDrawableGroup,
    projectId,
    activeFloor,
}: {
    location: BulkOpeningCreationData
    newDrawableGroup: GeometryGroup
    projectId: number
    activeFloor: ActiveFloor
}) {
    const drawable: CreateOpeningResponse = yield call(
        createOpening,
        projectId,
        activeFloor.hash,
        newDrawableGroup.id,
        location.coordinates,
        activeFloor.document_chunk.id,
        location.measurements,
        {
            unit_of_measure: location.settings.unit_of_measure,
            shape_type: location.settings.shape_type,
        },
        location.region_id,
        location.additional_data,
        location.ai_suggestion_id,
        location.cutouts
    )

    return yield call(updateDrawableLocationsAndOpeningGroups, drawable, newDrawableGroup.id, newDrawableGroup)
}

export function* createPathAndAddMetadata(
    location: IMUP2DDrawableLocation,
    areaOpacity: number,
    lineOpacity: number,
    shapeColor: paper.Color,
    regionPaths: paper.Path[]
) {
    const path: paper.Path = yield call(
        createPathItemFromType,
        location,
        location.coordinates,
        areaOpacity,
        lineOpacity,
        shapeColor,
        regionPaths
    )

    yield call(addMetadataToPath, path, '', location)
    path.data.drawable_id = TEMPORARY_DRAWABLE_ID

    return path
}

export function* updateStoreWithDrawable(drawable: CreateOpeningResponse, drawableGroup: GeometryGroup) {
    const drawableWithDoneState: GeometricDrawable = {
        ...drawable,
    }

    const newActiveDrawableGroup = {
        ...drawableGroup,
        openings: drawableGroup?.openings
            ? drawableGroup?.openings.concat([drawableWithDoneState])
            : [drawableWithDoneState],
        settings: drawable.opening_group.settings,
    }

    yield put({
        type: UPDATE_OPENING_GROUPS_SUCCESS,
        payload: {
            openingGroups: {
                newGroup: newActiveDrawableGroup,
                originalGroup: drawableGroup,
            },
        },
    })

    return newActiveDrawableGroup
}

export function* updateDrawableLocationsAndOpeningGroups(
    drawable: CreateOpeningResponse,
    drawableGroupId: number,
    drawableGroup: GeometryGroup,
    oldDrawableId?: number,
    oldLocationId?: number
) {
    const oldDrawable = drawableGroup.openings.find((drawable) => drawable.id === oldDrawableId)

    if (!oldDrawableId || oldDrawable?.opening_locations.length === 1) {
        yield call(addNewDrawableLocationToStore, drawable, drawableGroupId)

        return yield call(updateStoreWithDrawable, drawable, drawableGroup)
    } else if (oldLocationId && oldDrawable && oldDrawable?.opening_locations.length > 1) {
        const opLocationsToCreate = oldDrawable.opening_locations.filter((opLoc) => opLoc.id !== oldLocationId)

        let latestDrawable = drawable

        for (const opLoc of opLocationsToCreate) {
            latestDrawable = yield call(
                addOpeningLocation,
                drawable.id,
                opLoc.coordinates,
                drawable.opening_locations[0].document_chunk_id,
                drawable.project_id,
                drawable.opening_locations[0].region_id,
                opLoc.additional_data
            )
        }

        return yield call(updateStoreWithDrawable, latestDrawable, drawableGroup)
    }
}

export function* handlePasteMaterial(action: ReturnType<typeof pasteMaterialAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [selectTool, workspaceTool, colorTool]: [Select, Workspace, Color] = yield call(manager.getTools, [
        Select.NAME,
        Workspace.NAME,
        Color.NAME,
    ])

    const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)

    if (!activeFloor) return

    const projectId: number | null = yield select(selectProjectId)

    if (!projectId) return

    yield put(updateToolbarMessage(PASTING_MATERIAL_MESSAGE))

    yield call(selectTool.clearAllMultiSelections)
    yield put(copyDrawables([]))

    const locations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

    const drawableGroups: Record<string, GeometryGroup> = yield select(selectDrawableGroupsGeometriesHash, true)

    const regionPaths: paper.Path[] = yield call(workspaceTool.getAllRegionItems)

    const mapOfLocationByOpeningLocationIds: Map<number, IMUP2DDrawableLocation> = new Map()

    locations.forEach((l) => mapOfLocationByOpeningLocationIds.set(l.opening_location_id, l))

    const openingIMUPLocations: (IMUP2DDrawableLocation & { scaleFactor: number })[] = yield action.payload.reduce(
        (accLocations, locationId) => {
            const location = mapOfLocationByOpeningLocationIds.get(locationId)

            if (location) {
                // Determine if this drawable is inside a region so we can scale it appropriately
                let thisRegionGroup: paper.Path | null = null

                for (const regionPath of regionPaths) {
                    for (const c of location.coordinates) {
                        if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                            break
                        }
                        thisRegionGroup = regionPath
                    }
                }
                const scaleFactor = thisRegionGroup
                    ? thisRegionGroup.data.scale
                    : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR

                accLocations.push({
                    ...location,
                    region_id: thisRegionGroup ? thisRegionGroup.data.region_id : thisRegionGroup,
                    scaleFactor: convertScaleFactorLabelEnumToDecimal(scaleFactor),
                })
            }

            return accLocations
        },
        [] as (IMUP2DDrawableLocation & { scaleFactor: number })[]
    )

    const shapeColor = colorTool.createColor('#FFFFFF')
    const paths: paper.Path[] = yield all(
        openingIMUPLocations.map((loc) => {
            return createPathAndAddMetadata(loc, 0, 0, shapeColor, regionPaths)
        })
    )

    const { bulkOpeningsToCreate, floorAreaData } = openingIMUPLocations.reduce(
        (openingsData, location, i) => {
            const path = paths[i]
            const locationCreationData = {
                opening_group_id: location.opening_group_id,
                coordinates: location.coordinates,
                cutouts: location.cutouts || [],
                document_chunk_id: activeFloor.document_chunk.id,
                measurements: generateMeasurementsToUpdate({
                    coordinates: location.coordinates,
                    path,
                    settingsAndType: {
                        type: location.drawing_type,
                        settings: location.settings as GeneralDrawableSettings,
                    },
                    scaleFactor: location.scaleFactor,
                    pdfScale: activeFloor.document_chunk.pdf_scale || 1,
                    xCalibrationFactor: activeFloor.document_chunk.calibration_factor_x,
                    yCalibrationFactor: activeFloor.document_chunk.calibration_factor_y,
                    dpi: activeFloor.document_chunk.dpi ?? null,
                }),
                settings: {
                    unit_of_measure: getUnitOfMeasureByDrawingType(path.data.shapeType),
                    shape_type: path.data.shapeType,
                },
                region_id: location.region_id,
                additional_data: location.additionalData ?? {},
                ai_suggestion_id: path.data.aiSuggestion?.id,
                floor_hash: activeFloor.hash,
                oldDrawableId: location.drawable_id,
                oldLocationId: location.opening_location_id,
            }

            if (
                !!drawableGroups[location.opening_group_id] &&
                drawableGroups[location.opening_group_id]?.type !== DRAWABLE_TYPES.FLOOR_AREA
            ) {
                openingsData.bulkOpeningsToCreate.push(locationCreationData)
            } else if (!openingsData.floorAreaData[location.opening_group_id]) {
                openingsData.floorAreaData[location.opening_group_id] = {
                    group: drawableGroups[location.opening_group_id],
                    locations: [locationCreationData],
                }
            } else {
                openingsData.floorAreaData[location.opening_group_id].locations.push(locationCreationData)
            }

            return openingsData
        },
        {
            bulkOpeningsToCreate: [] as (BulkOpeningCreationData & { oldDrawableId: number; oldLocationId: number })[],
            floorAreaData: {} as Record<
                number,
                {
                    group: GeometryGroup
                    locations: BulkOpeningCreationData[]
                }
            >,
        }
    )

    yield all(paths.map((path) => call([path, path.remove])))

    let newMatGroups: ActiveGeometryGroup[] = []

    try {
        if (bulkOpeningsToCreate.length > 0) {
            const response: CreateOpeningResponse[] = yield call(
                bulkCreateOpenings,
                projectId,
                bulkOpeningsToCreate.map((data) => ({ ...data, oldDrawableId: undefined, oldLocationId: undefined }))
            )

            newMatGroups = yield all(
                response.map((res, i) =>
                    call(
                        updateDrawableLocationsAndOpeningGroups,
                        res,
                        res.opening_group_id,
                        drawableGroups[res.opening_group_id],
                        bulkOpeningsToCreate[i].oldDrawableId,
                        bulkOpeningsToCreate[i].oldLocationId
                    )
                )
            )
        }

        const newFloorAreaGroups: UpdateOpeningGroupApiResponse[] = yield all(
            Object.values(floorAreaData).map(({ group, locations }) => {
                return call(createFloorAreaGroupsAndOpenings, { group, locations, projectId, activeFloor })
            })
        )

        yield put(updateToolbarMessage(null))

        const newFloorAreaGroupsDrawableIds = newFloorAreaGroups.flatMap((newGroupRes) =>
            newGroupRes.newGroup.openings.reduce((openingIds, opening) => {
                const originalGroupOpeningIds = drawableGroups[newGroupRes.newGroup.id]?.openings.map((op) => op.id)

                if (!originalGroupOpeningIds || !originalGroupOpeningIds.includes(opening.id)) {
                    openingIds.push(opening.id)
                }

                return openingIds
            }, [] as number[])
        )

        const newMatDrawableIds = newMatGroups.flatMap((group) =>
            group.openings.reduce((openingIds, opening) => {
                const originalGroupOpeningIds = drawableGroups[group.id]?.openings.map((op) => op.id)

                if (!originalGroupOpeningIds || !originalGroupOpeningIds.includes(opening.id)) {
                    openingIds.push(opening.id)
                }

                return openingIds
            }, [] as number[])
        )

        yield put(selectPastedMaterialAction([...newMatDrawableIds, ...newFloorAreaGroupsDrawableIds]))
    } catch (e) {
        console.error(e)
        yield put(updateToolbarMessage(ERROR_PASTING_MATERIAL_MESSAGE))
        yield delay(TOOLBAR_MESSAGE_TIMER)
        yield put(updateToolbarMessage(null))
    }
}

export function* watchForPasteMaterial() {
    yield takeEvery(pasteMaterialAction.type, handlePasteMaterial)
}
