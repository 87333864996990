import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { REGION_ENUMS, SnappingSide } from '../../types'
import { SNAPPING_SIDE, SNAPPING_TOLERANCE } from '../../utils/constants'

/**
 * This utility function manages the snapping behavior while drawing
 * @param paper
 * @param input
 * @param options
 * @param options.tolerance the tolerance of the snapping
 * @returns
 */
export const snap = (
    paper: paper.PaperScope,
    input: paper.Point,
    options?: {
        tolerance: number
    }
): paper.Point | null => {
    const result = paper.project.hitTest(input, {
        tolerance: options?.tolerance ?? SNAPPING_TOLERANCE,
        fill: true,
        stroke: true,
        segments: true,
        match: hitFilter,
    })

    if (result?.item?.data?.shapeType === DRAWING_TYPES.POINT) {
        return result.item.position
    }

    return result ? result.point : null
}

/**
 * Filter out highlights and regions from the hitTest for snapping. This function is only called if there is a hit result that needs to be checked for filtering.
 * See http://paperjs.org/reference/project/#hittest-point match option for more info.
 * @param hit: HitResult
 * @returns boolean -> see documentation referenced above for more info
 */
export const hitFilter = (hit: paper.HitResult): boolean => {
    return !(
        hit.item.data.isHighlight ||
        hit.item.data.shapeType === REGION_ENUMS.TYPE ||
        hit.item.data.ignoreHitFilter
    )
}

/**
 * Get the closest point on the path to the given point
 * Calculate distance between the closest point and the given point
 *
 * @param point
 * @param path
 */
export const distanceToPath = (point: paper.Point, path: paper.Path): number => {
    const closestPoint = path.getNearestPoint(point)

    return closestPoint.getDistance(point)
}

export const getElementsWithinDistance = (paper: paper.PaperScope, point: paper.Point, distance: number) => {
    return paper.project.getItems({
        data: (data) => data?.drawable_id || data?.aiSuggestion?.id,
        match: (item) => {
            // ignore elements which are groups or hidden
            if (!!item?.children?.length || !item.visible) {
                return false
            }

            return point.getDistance(item?.getNearestPoint(point)) <= distance
        },
    })
}

export const getPreparedSegments = (path: paper.Path | paper.CompoundPath): paper.Segment[] => {
    if (path?.children && !!path.children.length) {
        // used [0] because it's a layout outside element
        return (path.children[0] as paper.Path).segments
    }

    return (path as paper.Path).segments
}

/**
 * Check if paths intersect with tolerance
 *
 * @param path1
 * @param path2
 * @param tolerance
 */

export const pathsIntersect = (path1: paper.Path, path2: paper.Path, tolerance: number): boolean => {
    const segments1 = getPreparedSegments(path1)
    const segments2 = getPreparedSegments(path2)

    // check if any point from path1 lies within path2 within tolerance
    for (let i = 0; i < segments1.length; i++) {
        const point = segments1[i].point

        if (distanceToPath(point, path2) <= tolerance) {
            return true
        }
    }

    // check if any point from path2 lies within path1 within tolerance
    for (let i = 0; i < segments2.length; i++) {
        const point = segments2[i].point

        if (distanceToPath(point, path1) <= tolerance) {
            return true
        }
    }

    return false // No intersections found
}

export const handleSnappingPoints = (snapItem: paper.Item, eventPoint: paper.Point) => {
    const distanceToSnapItem = snapItem.position.getDistance(eventPoint)

    return distanceToSnapItem <= SNAPPING_TOLERANCE ? snapItem.position : eventPoint
}

const getProjectionByPoints = (startPoint: paper.Point, endPoint: paper.Point, eventPoint: paper.Point) => {
    const lineVector = endPoint.subtract(startPoint)
    const pointVector = eventPoint.subtract(startPoint)

    return pointVector.project(lineVector)
}

/**
 * Utility to snap a point to a line segment
 *
 * @param startPoint
 * @param endPoint
 * @param eventPoint
 */
export const snapPointToSegment = (
    startPoint: paper.Point,
    endPoint: paper.Point,
    eventPoint: paper.Point
): { snappedPoint: paper.Point; distance: number } => {
    const lineVector = endPoint.subtract(startPoint)
    const projection = getProjectionByPoints(startPoint, endPoint, eventPoint)

    const pointOnTheLine = startPoint.add(projection)

    // Check if the projection is within the line segment bounds
    const projectionRatio = projection.divide(lineVector.length).dot(lineVector.normalize())

    let snappedPoint: paper.Point

    if (projectionRatio < 0) {
        // Snap to start point if projection is before the segment
        snappedPoint = startPoint
    } else if (projectionRatio > 1) {
        // Snap to end point if projection is beyond the segment
        snappedPoint = endPoint
    } else {
        // Otherwise, use the projected point on the line
        snappedPoint = pointOnTheLine
    }

    const distanceToSnapItem = snappedPoint.getDistance(eventPoint)

    return { snappedPoint, distance: distanceToSnapItem }
}

/**
 * Snap to a single line segment
 *
 * @param snapItem
 * @param eventPoint
 */
export const handleSnappingPointToSection = (snapItem: paper.Path, eventPoint: paper.Point): paper.Point => {
    const { snappedPoint, distance } = snapPointToSegment(
        snapItem.segments[0].point,
        snapItem.segments[1].point,
        eventPoint
    )

    return distance <= SNAPPING_TOLERANCE ? snappedPoint : eventPoint
}

/**
 * Snap to a polygon or closed area
 *
 * @param snapItem
 * @param eventPoint
 */
export const handleSnappingPointToArea = (snapItem: paper.Path, eventPoint: paper.Point): paper.Point => {
    let closestPoint: paper.Point | null = null
    let minDistance = Infinity

    // Iterate over the segments of the area to find the closest point
    for (let i = 0; i < snapItem.segments.length; i++) {
        const startPoint = snapItem.segments[i].point
        const endPoint = snapItem.segments[(i + 1) % snapItem.segments.length].point

        const { snappedPoint, distance } = snapPointToSegment(startPoint, endPoint, eventPoint)

        if (distance < minDistance) {
            minDistance = distance
            closestPoint = snappedPoint
        }
    }

    return minDistance <= SNAPPING_TOLERANCE ? closestPoint! : eventPoint
}

const handleSnappingSectionToAPoint = (item: paper.Path, movingItem: paper.Path, tolerance: number) => {
    const startPoint = (movingItem as paper.Path).segments[0].point
    const endPoint = (movingItem as paper.Path).segments[1].point

    const startDistance = startPoint.getDistance(item.position)
    const endDistance = endPoint.getDistance(item.position)

    const offset = endDistance < startDistance ? item.position.subtract(endPoint) : item.position.subtract(startPoint)

    const adjustedPointRadius = 15

    if (offset.length <= tolerance + adjustedPointRadius) {
        ;(movingItem as paper.Path).segments[0].point = startPoint.add(offset)
        ;(movingItem as paper.Path).segments[1].point = endPoint.add(offset)
    }
}

/**
 * Snap to each side of area/area with cutout
 *
 * @param paper
 * @param item
 * @param movingItem
 */
const handleSnappingAreaToAPoint = (paper: paper.PaperScope, item: paper.Path, movingItem: paper.Path) => {
    const targetPoint = item.position

    let nearestPoint: paper.Point | null = null
    let minDistance = Infinity

    const findNearestPointOnPath = (path: paper.Path) => {
        path.segments.forEach((segment, index) => {
            const nextSegment = path.segments[(index + 1) % path.segments.length]
            const edgeNearestPoint = new paper.Path.Line(segment.point, nextSegment.point).getNearestPoint(targetPoint)

            const distance = edgeNearestPoint.getDistance(targetPoint)

            if (distance < minDistance) {
                minDistance = distance
                nearestPoint = edgeNearestPoint
            }
        })
    }

    if (movingItem.className === 'CompoundPath') {
        movingItem.children.forEach((child) => {
            findNearestPointOnPath(child as paper.Path)
        })
    } else {
        findNearestPointOnPath(movingItem)
    }

    if (nearestPoint) {
        const snapDelta = targetPoint.subtract(nearestPoint)

        movingItem.position = movingItem.position.add(snapDelta)
    }
}

/**
 * Handle snapping of point to point/section/area/area with cutout
 *
 * Should receive event point, since base on event point we calculate easy un snap
 *
 * @param item
 * @param eventPoint
 */
const handleSnapMovingPoint = (item: paper.Path, eventPoint: paper.Point): paper.Point | null => {
    if (item.data.shapeType === DRAWING_TYPES.POINT) {
        return handleSnappingPoints(item, eventPoint)
    }

    if (item.data.shapeType === DRAWING_TYPES.SECTION) {
        return handleSnappingPointToSection(item as paper.Path, eventPoint)
    }

    if (item.data.shapeType === DRAWING_TYPES.AREA || (item as paper.Path).segments.length > 2) {
        return handleSnappingPointToArea(item as paper.Path, eventPoint)
    }

    console.error(`Can't snap to a ${item.data.shapeType}`)

    return null
}

/**
 * Return new snap point base on calculation
 *
 * @param paper
 * @param item - all items on current page that can be snapped
 * @param movingItem - drawing element
 * @param tolerance - px when we can snap
 * @param eventPoint - used to snap on draw
 */
export const getSnapPosition = (
    paper: paper.PaperScope,
    item: paper.Item,
    movingItem: paper.Item,
    tolerance: number,
    eventPoint: paper.Point | null
): paper.Point | null => {
    // handle point snapping
    if (eventPoint && movingItem.data.shapeType === DRAWING_TYPES.POINT) {
        return handleSnapMovingPoint(item as paper.Path, eventPoint)
    }

    // handle snap section/area/area with cutout to a point
    // we return null since we change position of element
    if (item.data.shapeType === DRAWING_TYPES.POINT) {
        if (eventPoint && movingItem.data.shapeType === DRAWING_TYPES.SECTION) {
            handleSnappingSectionToAPoint(item as paper.Path, movingItem as paper.Path, tolerance)

            return null
        }

        if (eventPoint && movingItem.data.shapeType === DRAWING_TYPES.AREA) {
            handleSnappingAreaToAPoint(paper, item as paper.Path, movingItem as paper.Path)

            return null
        }
    }

    // rest base snapping
    if (Math.abs(item.bounds.right - movingItem.bounds.left) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(item.bounds.right, eventPoint.y)
        }

        if (item.data.shapeType === DRAWING_TYPES.POINT) {
            return new paper.Point(item.position.x, movingItem.position.y)
        }

        // snap on the right
        return new paper.Point(item.bounds.right + movingItem.bounds.width / 2, movingItem.position.y)
    } else if (Math.abs(item.bounds.left - movingItem.bounds.right) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(item.bounds.left, eventPoint.y)
        }

        if (item.data.shapeType === DRAWING_TYPES.POINT) {
            return new paper.Point(item.position.x, movingItem.position.y)
        }

        // snap on the left
        return new paper.Point(item.bounds.left - movingItem.bounds.width / 2, movingItem.position.y)
    } else if (Math.abs(item.bounds.top - movingItem.bounds.bottom) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(eventPoint.x, item.bounds.top)
        }

        if (item.data.shapeType === DRAWING_TYPES.POINT) {
            return new paper.Point(item.position.y, movingItem.position.x)
        }

        // snap on the top
        return new paper.Point(movingItem.position.x, item.bounds.top - movingItem.bounds.height / 2)
    } else if (Math.abs(item.bounds.bottom - movingItem.bounds.top) <= tolerance) {
        if (eventPoint) {
            return new paper.Point(eventPoint.x, item.bounds.bottom)
        }

        if (item.data.shapeType === DRAWING_TYPES.POINT) {
            return new paper.Point(item.position.y, movingItem.position.x)
        }

        // snap on the bottom
        return new paper.Point(movingItem.position.x, item.bounds.bottom + movingItem.bounds.height / 2)
    }

    return null
}

/**
 * Calculate snap point for each drawing tool
 * @param items
 * @param eventPoint
 */
export const getSnapPoint = (items: paper.Item[], eventPoint: paper.Point): paper.Point => {
    let snapPoint: paper.Point = eventPoint

    items.forEach((path) => {
        if (snapPoint && distanceToPath(snapPoint, path as paper.Path) <= SNAPPING_TOLERANCE) {
            snapPoint =
                path.data.shapeType === DRAWING_TYPES.POINT
                    ? path.position
                    : (path as paper.Path).getNearestPoint(snapPoint)
        }
    })

    return snapPoint
}

/**
 * Using path and event point calculate the snap point
 * @param paper
 * @param path - path to check if we can snap
 * @param eventPoint - event point which is moved
 * @param tolerance - number when we should snap
 */
export const getSnapPositionForEventPoint = (
    paper: paper.PaperScope,
    path: paper.Path,
    eventPoint: paper.Point,
    tolerance: number = SNAPPING_TOLERANCE
): SnappingSide => {
    const { top, right, bottom, left } = path.bounds

    const inBetweenHorizontal = left <= eventPoint.x && eventPoint.x <= right
    const inBetweenVertical = top <= eventPoint.y && eventPoint.y <= bottom

    if (inBetweenHorizontal && !inBetweenVertical && Math.abs(path.bounds.top - eventPoint.y) <= tolerance) {
        return { side: SNAPPING_SIDE.TOP, point: new paper.Point(eventPoint.x, path.bounds.top) }
    }

    if (inBetweenVertical && !inBetweenHorizontal && Math.abs(path.bounds.right - eventPoint.x) <= tolerance) {
        return { side: SNAPPING_SIDE.RIGHT, point: new paper.Point(path.bounds.right, eventPoint.y) }
    }

    if (inBetweenHorizontal && !inBetweenVertical && Math.abs(path.bounds.bottom - eventPoint.y) <= tolerance) {
        return { side: SNAPPING_SIDE.BOTTOM, point: new paper.Point(eventPoint.x, path.bounds.bottom) }
    }

    if (inBetweenVertical && !inBetweenHorizontal && Math.abs(path.bounds.left - eventPoint.x) <= tolerance) {
        return { side: SNAPPING_SIDE.LEFT, point: new paper.Point(path.bounds.left, eventPoint.y) }
    }

    return { side: SNAPPING_SIDE.NONE, point: eventPoint }
}

/**
 * Get position between items to snap on draw
 *
 * @param paper
 * @param eventPoint
 * @param nearElementsPoints
 */
export const getSnapPointBetweenItems = (
    paper: paper.PaperScope,
    eventPoint: paper.Point,
    nearElementsPoints: SnappingSide[]
): paper.Point => {
    let calculatedX = eventPoint.x
    let calculatedY = eventPoint.y

    const sideMap: { [key: string]: (point: paper.Point) => void } = {
        [SNAPPING_SIDE.TOP]: (point) => (calculatedY = point.y),
        [SNAPPING_SIDE.RIGHT]: (point) => (calculatedX = point.x),
        [SNAPPING_SIDE.BOTTOM]: (point) => (calculatedY = point.y),
        [SNAPPING_SIDE.LEFT]: (point) => (calculatedX = point.x),
    }

    nearElementsPoints.forEach((element) => {
        if (sideMap[element.side]) {
            sideMap[element.side](element.point)
        }
    })

    return new paper.Point(calculatedX, calculatedY)
}

/**
 * On drawing element, detect the closest element where distance is less or equal to SNAPPING_TOLERANCE
 * and return the snap point for one element or calculate for multiple points
 *
 * @param paper
 * @param eventPoint
 * @param nearElements
 */
export const getSnapPointOnDraw = (
    paper: paper.PaperScope,
    eventPoint: paper.Point,
    nearElements: paper.Path[]
): paper.Point => {
    const nearElementsPoints: SnappingSide[] = nearElements.map((element) => {
        return getSnapPositionForEventPoint(paper, element, eventPoint, SNAPPING_TOLERANCE)
    })

    // when single item is the near one, use that item point
    if (nearElementsPoints.length === 1) {
        return nearElementsPoints[0].point
    }

    // when multiple items found, calculate initial point from where drawing is started
    return getSnapPointBetweenItems(paper, eventPoint, nearElementsPoints)
}
