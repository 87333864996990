import { createAction } from '@reduxjs/toolkit'
import { call, takeEvery } from 'redux-saga/effects'

import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'

export const selectPastedMaterialAction = createAction<number[]>('selectPastedMaterial')

export function* handleSelectPastedMaterial(action: ReturnType<typeof selectPastedMaterialAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [selectTool, workspaceTool]: [Select, Workspace] = yield call(manager.getTools, [Select.NAME, Workspace.NAME])

    let items: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
        action.payload.includes(data.drawable_id)
    )

    let visibleItems = items.filter((item) => item.visible === true)

    while (visibleItems.length < action.payload.length) {
        items = workspaceTool.getItemsWithCriteria('data', (data) => action.payload.includes(data.drawable_id))
        visibleItems = items.filter((item) => item.visible === true)
    }

    yield call([selectTool, 'createMultiSelectionGroupFromItems'], visibleItems)
}

export function* watchForSelectPastedMaterial() {
    yield takeEvery(selectPastedMaterialAction.type, handleSelectPastedMaterial)
}
