import defaultTo from 'lodash/defaultTo'
import isNull from 'lodash/isNull'
import { call, CallEffect, PutEffect, select, SelectEffect } from 'redux-saga/effects'

import createPathItemFromType, { getScaleFactor } from './createPathItemFromType'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIMaterialByFloor, AISuggestion } from '../../../models/aiClassifications'
import { AI_MATERIAL_REFLECTED_GRADIENT_COLORS, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { drawableBackgroundAndBorderColor } from '../../../shared/services/drawable-color-services'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToAiSuggestion from '../../lib/utils/functionality-bindings/addSelectFunctionalityToAiSuggestion'
import { selectHiddenAIMaterialGroups } from '../../slices/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { initialToolsState } from '../../slices/tools'
import { ITool } from '../../types'

type DrawAiSuggestionsByTypeYield =
    | CallEffect<
          | string
          | paper.Color
          | paper.Path
          | void
          | paper.Raster
          | paper.Group
          | ITool[]
          | PaperManager
          | null
          | managerTools
      >
    | PutEffect
    | SelectEffect

type DrawAiSuggestionsByTypeNext = string &
    paper.Color &
    (paper.Raster | null) &
    paper.Path &
    paper.Group &
    ITool &
    PaperManager &
    (ActiveFloor | null) &
    managerTools &
    AIMaterialByFloor[]

type managerTools = [Color, Workspace]

export default function* drawAiSuggestionsByType(
    aiSuggestion: AISuggestion,
    areaOpacity: number,
    lineOpacity: number,
    regionPaths: paper.Path[]
): Generator<DrawAiSuggestionsByTypeYield, paper.Path | null, DrawAiSuggestionsByTypeNext> {
    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return null

    // Get the active floor from the store
    const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

    const { coordinates, type, settings, shape_type } = aiSuggestion

    const [colorTool, workspaceTool]: managerTools = yield call(manager.getTools, [Color.NAME, Workspace.NAME])

    // determine color by location settings and drawable_type
    const colorValueFromConstants: string = yield call(
        drawableBackgroundAndBorderColor,
        defaultTo(type, ''),
        settings.name,
        settings.selection,
        settings.location,
        {},
        settings.material
    )

    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, colorValueFromConstants)

    const path: paper.Path = yield call(
        createPathItemFromType,
        aiSuggestion,
        coordinates,
        areaOpacity,
        lineOpacity,
        shapeColor,
        regionPaths
    )

    // set visibility
    const hiddenMaterialGroups: AIMaterialByFloor[] = yield select(selectHiddenAIMaterialGroups)
    const aiIds = hiddenMaterialGroups.flatMap((x) => x.aiMaterials.map((material) => material.id))

    if (aiIds.includes(aiSuggestion.id)) {
        path.visible = false
    }

    const scaleFactor = getScaleFactor(coordinates, workspaceTool, activeFloor, regionPaths)
    const gradientColors = AI_MATERIAL_REFLECTED_GRADIENT_COLORS[type]

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, aiSuggestion)
    yield call(addSelectFunctionalityToAiSuggestion, path)

    // add gradient for AI-drawn lines
    if (shape_type === DRAWING_TYPES.SECTION && gradientColors) {
        path.strokeColor = yield call(
            colorTool.createReflectedGradient,
            gradientColors.outerColor,
            gradientColors.innerColor,
            coordinates
        )
    }

    return path
}
