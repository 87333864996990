import { DRAWABLE_TYPES } from '../../shared/constants/drawable-types'

export const COPY_3D_PROJECT_URL = '/copy-3d-project'

export const NONE_MATERIAL_VALUE = 'None'
export const EMPTY_PAGE_SCALE_VALUE = 'Select scale before digitizing'

export const SNAPPING_TOLERANCE = 20

export enum SNAPPING_SIDE {
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left',
    NONE = 'none',
}

export const hiddenMaterialOptionsList: DRAWABLE_TYPES[] = [
    DRAWABLE_TYPES.LE_DOOR,
    DRAWABLE_TYPES.LE_UNKNOWN,
    DRAWABLE_TYPES.LE_WINDOW,
    DRAWABLE_TYPES.PATIO,
    DRAWABLE_TYPES.SIDELIGHT,
    DRAWABLE_TYPES.AUTOMATED_BEAM_AND_HEADER,

    DRAWABLE_TYPES.RAFTER,
    DRAWABLE_TYPES.CEILING_JOIST,
    DRAWABLE_TYPES.FLOOR_JOIST,
    DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM,
    DRAWABLE_TYPES.I_JOIST,
    DRAWABLE_TYPES.LE_JOIST,
    DRAWABLE_TYPES.SLEEPER_JOIST,

    DRAWABLE_TYPES.ENTRY_DOOR,
    DRAWABLE_TYPES.FLOOR_LINE,
]

export const materialDropdownSortOrder = [
    DRAWABLE_TYPES.APPLIANCE,
    DRAWABLE_TYPES.FLOOR_AREA,
    DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING,
    DRAWABLE_TYPES.GARAGE_AREA,
    DRAWABLE_TYPES.FRAMING_ROOFING,
    DRAWABLE_TYPES.GABLE_AREA,
    DRAWABLE_TYPES.EAVE_LENGTH,
    DRAWABLE_TYPES.GABLE_LENGTH,
    DRAWABLE_TYPES.TRIM,
    DRAWABLE_TYPES.BLOCK_ROUGH,
    DRAWABLE_TYPES.CORNER,
    DRAWABLE_TYPES.WALL,
    DRAWABLE_TYPES.SHEARWALL,
    DRAWABLE_TYPES.FOUNDATION_PILON,
    DRAWABLE_TYPES.LOUVER_HOG_FENCE,
    DRAWABLE_TYPES.FLOOR_SYSTEM,
    DRAWABLE_TYPES.FLOOR_TRUSS_BRACING,
    DRAWABLE_TYPES.CANTILEVER_CLOSURE,
    DRAWABLE_TYPES.LOUVER_HOG_FENCE,
    DRAWABLE_TYPES.LEDGER,
    DRAWABLE_TYPES.BLOCKING,
    DRAWABLE_TYPES.STEEL_BEAM_PLATE,
    DRAWABLE_TYPES.STAIRS,
    DRAWABLE_TYPES.POST,
    DRAWABLE_TYPES.LF_RAILING,
    DRAWABLE_TYPES.SCREEN_AREA,
    DRAWABLE_TYPES.LF_SCREEN_OPENING,
    DRAWABLE_TYPES.ROOF_SYSTEM,
    DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT,
    DRAWABLE_TYPES.CANALES,
    DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING,
    DRAWABLE_TYPES.EXTERIOR_DOOR,
    DRAWABLE_TYPES.INTERIOR_DOOR_IMUP,
    DRAWABLE_TYPES.HARDWARE,
    DRAWABLE_TYPES.WILDCARD_AREA,
    DRAWABLE_TYPES.WILDCARD_COUNT,
    DRAWABLE_TYPES.WILDCARD_LINE,
    DRAWABLE_TYPES.VALLEY_PLATE,
    DRAWABLE_TYPES.DROPPED_BEAM,
    DRAWABLE_TYPES.FLUSH_BEAM,
    DRAWABLE_TYPES.RIDGE_BEAM,
    DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM,
    DRAWABLE_TYPES.HEADER,

    DRAWABLE_TYPES.EXTERIOR_ROOFING,
    DRAWABLE_TYPES.ROOF_LINE,
    DRAWABLE_TYPES.FLASHING,
    DRAWABLE_TYPES.SIDING,
    DRAWABLE_TYPES.SIDING_STARTER,
    DRAWABLE_TYPES.PORCH_CEILING,
    DRAWABLE_TYPES.POST_WRAP,
    DRAWABLE_TYPES.FYPON_BRACKET,
]

export const NO_AI_CLASSIFICATION_DATA = {
    id: -1,
    ai_classification_data: '',
}
