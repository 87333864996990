import { AjvError, UiSchema } from '@rjsf/core'
import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

import { IFormDataToolForm } from './components/material-forms/models/IFormDataToolForm'
import {
    cornerDrawablesSortOrder,
    DRAWABLE_GROUPPING_NAMES,
    droppedBeamDrawablesSortOrder,
    eaveGableDrawablesSortOrder,
    exteriorRoofingDrawablesSortOrder,
    floorTrussDrawablesSortOrder,
    roofingBeamDrawablesSortOrder,
    wallsDrawablesSortOrder,
} from './constants/drawables-sort-order'
import { GeometryGroup, GeometryGroupWithTempType } from '../../../imup/slices/geometry'
import { ActiveDrawable, OpeningGroup } from '../../../models/activeDrawable'
import { GeneralDrawableSettings } from '../../../models/activeDrawableSettings'
import { AIAutomatedObject, AIMaterial } from '../../../models/aiClassifications'
import { Project } from '../../../models/project'
import { DrawableGroupsCategorizedByBundleAndSorted } from '../../../shared/components/navigation-tree/utils/types'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES, TRANSFORM_ERRORS } from '../../../shared/constants/error-messages'
import { FILTER_CARD_VALUES } from '../../../shared/constants/filter-cards.constants'
import { PROJECT_TYPES_ENUM } from '../../../shared/constants/project-type.constants'
import { PROJECT_STATUS_NAME } from '../../../shared/constants/projectStatusName'
import { addTempTypeToDrawables } from '../../../shared/services/drawable-groups-service'
import { sortASCByNumberOrText } from '../../../utils/sorting'
import { capitalizeEachWord, convertStringToBool } from '../../../utils/stringFormatters'

const handleSortDrawableGroupsDrawables = (
    drawableGroupType: DRAWABLE_GROUPPING_NAMES | DRAWABLE_TYPES,
    drawableGroupDrawables: GeometryGroup[]
): GeometryGroupWithTempType[] => {
    // sort by specific order
    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.WALL) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order: wallsDrawablesSortOrder.indexOf(drawable.settings.floor_level?.toLowerCase() ?? '') + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.DROPPED_BEAM) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order:
                        droppedBeamDrawablesSortOrder.indexOf(
                            `${drawable.settings.type?.toLowerCase()?.replace('_', ' ')} ${
                                drawable.settings?.material?.toLowerCase() || ''
                            }`.trim()
                        ) + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_TYPES.CORNER) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order: cornerDrawablesSortOrder.indexOf(`${drawable.settings.location?.toLowerCase()}`.trim()) + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.FLOOR_TRUSS) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order: floorTrussDrawablesSortOrder.indexOf(`${drawable.settings.type?.toLowerCase()}`.trim()) + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.EAVE_GABLE) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order:
                        eaveGableDrawablesSortOrder.indexOf(
                            `${drawable.settings.name
                                ?.replaceAll(drawable.settings.selection ?? '', '')
                                ?.toLowerCase()}`.trim()
                        ) + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.ROOFING_BEAM) {
        return drawableGroupDrawables
            .map((drawable) => {
                return {
                    ...drawable,
                    order: roofingBeamDrawablesSortOrder.indexOf(`${drawable.type}`.trim()) + 1,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    if (drawableGroupType === DRAWABLE_GROUPPING_NAMES.EXTERIOR_ROOFING) {
        return drawableGroupDrawables
            .map((drawable) => {
                const order = exteriorRoofingDrawablesSortOrder.findIndex((name) =>
                    drawable.settings.name
                        ?.replace(drawable.settings?.selection ?? '', '')
                        ?.toLowerCase()
                        .includes(name)
                )

                return {
                    ...drawable,
                    order: order === -1 ? exteriorRoofingDrawablesSortOrder.length + 1 : order,
                }
            })
            .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
    }

    // general sorting by drawable name
    return drawableGroupDrawables.sort((a, b) => sortASCByNumberOrText(a.settings.name ?? '', b.settings.name ?? ''))
}

export const sortedDrawableGroups = (openingGroups: GeometryGroup[], visibleCards: FILTER_CARD_VALUES) => {
    // added tempType to group them and do not change a current type
    const drawablesWithTempType = addTempTypeToDrawables(openingGroups)

    const groupedDrawables = groupBy(drawablesWithTempType, (group) => {
        const { bundle_name, bundle_location, bundle_floor_level, bundle_other_floor_level } = group.settings

        const floorLevel = bundle_other_floor_level || bundle_floor_level

        if (bundle_name !== 'FRAMING' && bundle_name !== 'EXTERIOR') {
            return `${floorLevel || ''} ${bundle_location || ''} ${bundle_name}`.trim()
        }

        return group.tempType
    })

    return Object.entries(groupedDrawables)
        .map((drawableGroup) => {
            const drawableGroupType = drawableGroup[0]
            const drawableGroupDrawables = drawableGroup[1]

            return {
                type: drawableGroupType,
                order: Object.keys(DRAWABLE_GROUPPING_NAMES).indexOf(drawableGroupType.toUpperCase()) + 1,
                openingGroups: handleSortDrawableGroupsDrawables(
                    DRAWABLE_GROUPPING_NAMES[drawableGroupType.toUpperCase()] ||
                        DRAWABLE_TYPES[drawableGroupType.toUpperCase()],
                    drawableGroupDrawables
                ),
            }
        })
        .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
}

export const sortDrawableGroupsByBundleNameOrType = (
    openingGroups: GeometryGroup[],
    visibleCards: FILTER_CARD_VALUES
): DrawableGroupsCategorizedByBundleAndSorted[] => {
    const groupedDrawables = groupBy(openingGroups, 'settings.folder')

    return Object.entries(groupedDrawables).map(([bundleTitle, groupsInBundle]) => {
        return { title: bundleTitle, sortedGroupsInBundle: sortedDrawableGroups(groupsInBundle, visibleCards) }
    })
}

export const sortAIByTypeAndSettings = (
    aiSuggestions: AIAutomatedObject[]
): {
    type: DRAWABLE_TYPES
    settingsGroups: AIMaterial[]
}[] => {
    const groupedMaterials = aiSuggestions.reduce((acc, suggestion) => {
        const { type, settings } = suggestion
        const { location, size } = settings
        const settingsName = capitalizeEachWord(
            `${settings.type}${location ? ` - ${location}` : ''}${size ? ` - ${size}` : ''}`
        )
        const typeGroup = acc.find((group) => group.type === type)

        if (!typeGroup) {
            acc.push({
                type,
                settingsGroups: [
                    {
                        type,
                        name: settingsName,
                        aiMaterials: [suggestion],
                    },
                ],
            })
        } else {
            const settingsGroup = typeGroup.settingsGroups.find((group) => group.name === settingsName)

            if (!settingsGroup) {
                typeGroup.settingsGroups.push({
                    type,
                    name: settingsName,
                    aiMaterials: [suggestion],
                })
            } else {
                settingsGroup.aiMaterials.push(suggestion)
            }
        }

        return acc
    }, [] as { type: DRAWABLE_TYPES; settingsGroups: AIMaterial[] }[])

    return groupedMaterials
}

/** Method returns true, if project type is Markup or Markup Only */
export const isMarkupProject = (project: Project): boolean => {
    return (
        project.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP ||
        project.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY
    )
}

export const doesProjectNeedDigitization = (project: Project): boolean => {
    const isMarkup = isMarkupProject(project)
    const isProjectStatusNeedDigitization =
        project?.projectStatus?.isProjectEditable ||
        project?.projectStatus?.name === PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION

    return isMarkup && isProjectStatusNeedDigitization
}

const removeNullFieldsDeep = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    // recursively process each key-value pair
    const result = Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value !== null) {
            const cleanedValue = removeNullFieldsDeep(value)

            if (Object.keys(cleanedValue).length > 0) {
                acc[key] = cleanedValue
            }
        } else if (value !== null && value !== '') {
            acc[key] = value
        }

        return acc
    }, {})

    return result
}

export const prepareActiveDrawableSettings = (
    uiSchema: UiSchema,
    settings: ActiveDrawable['settings']
): ActiveDrawable['settings'] =>
    Object.keys(settings).reduce((newSettings, key) => {
        if (uiSchema?.[key]?.['ui:field'] === 'SwitchField') {
            newSettings[key] = convertStringToBool(settings[key])
        } else if (
            isNull(settings[key]) ||
            (typeof settings[key] !== 'number' && typeof settings[key] !== 'boolean' && isEmpty(settings[key]))
        ) {
            // if there is no value, just remove the field
            delete newSettings[key]
        } else if (isArray(settings[key]) || typeof settings[key] === 'boolean') {
            // keep arrays and booleans as it is, do not convert them into string
            newSettings[key] = settings[key]
        } else if (!isUndefined(settings[key]) && typeof settings[key] !== 'object') {
            // All values supplied by selections API are strings and accepted by gateway/material calc.
            // "Other" free text input fields should be strings as well
            newSettings[key] = String(settings[key])
        } else if (typeof settings[key] === 'object' && !isUndefined(settings[key])) {
            newSettings[key] = removeNullFieldsDeep(settings[key])
        }

        return newSettings
    }, {})

/**
 * Receive distance and convert to feet and inches
 *
 * @param distance
 */
export const convertAbsoluteDistanceToFeetAndInches = (distance: number): { feet: number; inches: number } => {
    const feet = Math.floor(distance)
    const inches = Math.round((distance - feet) * 12)

    // in the case of 12 inches, convert, add 1 to feet and display 0 in inches
    // 4 feet 12 inches should be 5 feet 0 inches
    const preparedFeet = inches === 12 ? feet + 1 : feet
    const preparedInches = inches === 12 ? 0 : inches

    return {
        feet: preparedFeet,
        inches: preparedInches,
    }
}

/**
 * get feet and inches and format as string
 *
 * @param distance
 */
export const convertAbsoluteDistanceToFormattedString = (distance: number): string => {
    const { feet, inches } = convertAbsoluteDistanceToFeetAndInches(distance)

    return `${feet}' ${inches}"`
}

export const prepareSettingsToCompare = (settings: OpeningGroup['settings']) => {
    const newObject = {}

    Object.keys(settings).forEach((key) => {
        if (
            key === 'totalQuantity' ||
            key === 'total_quantity' ||
            key === 'quantity' ||
            key === 'linear_total' ||
            key === 'area' ||
            key === 'count'
        ) {
            return
        }

        if (settings[key] === null) {
            return (newObject[key] = undefined)
        }

        if (typeof settings[key] === 'number') {
            return (newObject[key] = String(settings[key]))
        }

        return (newObject[key] = settings[key])
    })

    return newObject
}

export const transformErrors = (errors: AjvError[], formData: GeneralDrawableSettings | null | IFormDataToolForm) => {
    const errorList: AjvError[] = []

    // prevent display error message if field expect to get value but value doesn't exist
    errors.forEach((error: AjvError) => {
        // get all the transform errors keys and values and find in list of errors
        const errorPatternFound = Object.entries(TRANSFORM_ERRORS).find(
            ([, pattern]) => pattern === error.params.pattern
        )

        // if there is pattern in schema and we defined error message set it
        if (!!errorPatternFound?.[0] && FORM_ERROR_MESSAGES?.[errorPatternFound[0]]) {
            error.message = FORM_ERROR_MESSAGES[errorPatternFound[0]]
        }

        // we would like to allow null values, even if it's not present in options
        // if null is not allowed, isRequired error will do the validation
        // property format ex is '.location', remove dot to find the value
        if (error.message !== FORM_ERROR_MESSAGES.EMPTY_CASE && formData) {
            error.property.replace(/^./, '')
            errorList.push(error)
        }
    })

    return errorList
}

/**
 * Parse `8'6"` to { feet: 8, inches: 6 }
 * Parse `8'3/4"` to { feet: 8, inches: 3/4 }
 * Parse `8'1-3/4"` to { feet: 8, inches: 1-3/4 }
 * @param feetAndInches
 */
export const parseFeetAndInches = (feetAndInches: string): { feet: string; inches: string } | undefined => {
    const pattern = /(\d+)'((\d+-)?\d+(\/\d+)?)"/
    const match = feetAndInches.match(pattern)

    if (match) {
        const feet = match[1]
        const inches = match[2]

        return { feet, inches }
    }

    return undefined
}

/**
 * Based on feet and inches object, return the string
 * @param feetAndInches
 */
export const generateFeetAndInchesString = (feetAndInches: { feet: string; inches: string }): string => {
    const feet = feetAndInches.feet || '0'
    const inches = feetAndInches.inches || '0'

    return `${feet}'${inches}"`
}
