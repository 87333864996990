import { createAction } from '@reduxjs/toolkit'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { resetDrawables } from './resetDrawables'
import { updateAutomatedObject } from '../../../api/takeoff-api'
import { AIAutomatedObject } from '../../../models/aiClassifications'
import { markAiSuggestionsDeleted } from '../../../utils/aiSuggestions/aiParser'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectAiSuggestions, setAiSuggestions } from '../../slices/2D'
export const deleteAISuggestionAction = createAction<string[]>('deleteAISuggestionAction')
export function* handleDeleteAISuggestion({ payload: aiSuggestionId }: ReturnType<typeof deleteAISuggestionAction>) {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const aiDataArray: AIAutomatedObject[] = yield select(selectAiSuggestions)
        const updatedAiData: AIAutomatedObject[] = yield markAiSuggestionsDeleted(aiDataArray, aiSuggestionId)

        yield all(aiSuggestionId.map((id) => call(updateAutomatedObject, id, true, false)))
        yield put(setAiSuggestions(updatedAiData))

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
        const aiItems: paper.Path[] = yield call(workspaceTool.getItemsWithCriteria, 'data', function (data: any) {
            return aiSuggestionId.includes(data?.aiSuggestion?.id)
        })

        yield all(aiItems.map((item) => call(item.remove.bind(item))))
    } catch (e) {
        console.error('Error on deleting updating AI', e)
    }
}
export function* watchForDeleteAISuggestion() {
    yield takeLatest(deleteAISuggestionAction.type, handleDeleteAISuggestion)
}
