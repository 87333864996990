import { createAction, createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import {
    all,
    AllEffect,
    call,
    CallEffect,
    fork,
    ForkEffect,
    put,
    select,
    StrictEffect,
    takeLatest,
} from 'redux-saga/effects'

import drawShapeByType from './drawShapeByType'
import drawToolByType from './drawToolByType'
import { drawAISuggestions } from './handleDrawAISuggestions'
import { handleToggleDrawableGroups2D } from './handleToggleDrawableGroups'
import {
    handleToggleMaterialFlagsVisibility,
    toggleMaterialFlagsVisibilityAction,
} from './handleToggleMaterialFlagsVisibility'
import {
    handleToggleRegionLinesVisibility,
    toggleRegionLinesVisibilityAction,
} from './handleToggleRegionLinesVisibility'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIAutomatedObject } from '../../../models/aiClassifications'
import { DocumentMapping } from '../../../models/documentMapping'
import { NormalizedFlag, OpeningLinkWithFlagId } from '../../../models/flags'
import { Region } from '../../../models/region'
import { REGION_COLOR } from '../../../shared/constants/colors'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { RootState } from '../../../stores'
import { convertScaleFactorLabelEnumToDecimal } from '../../../utils/calculations/scaleConversion/scaleConversion'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import {
    Color,
    Comment,
    Flag,
    Highlight,
    Image,
    Label,
    PathTool,
    RegionTool,
    Select,
    Workspace,
} from '../../lib/toolBoxes/2D'
import addTooltipsToPath from '../../lib/utils/functionality-bindings/addTooltipsToPath'
import { createMultiSelectPredicates } from '../../lib/utils/MultiSelect'
import { getCurrentDrawableLocations, updateScaleFactor } from '../../slices/2D'
import { DocumentsState, selectDocumentMappings, selectDrawableActiveFloor } from '../../slices/documents'
import { FlagsState } from '../../slices/flags'
import { GeometryGroup, selectDrawableGroupsGeometries } from '../../slices/geometry'
import { sceneRendered, sceneRendering } from '../../slices/loading'
import { MaterialFlagsState } from '../../slices/materialFlags'
import { RegionState } from '../../slices/region'
import { selectVisibility, setCalibrationRatio, ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation, REGION_ENUMS } from '../../types'

// Action for triggering document markup
export const draw2DMarkup = createAction('draw2DMarkup')

interface DocumentMarkupStoreState {
    activeFloor: ActiveFloor | null
    drawableLocations: IMUP2DDrawableLocation[]
    openingGroups: GeometryGroup[]
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
    regions: RegionState['regions']
    documentChunks: DocumentsState['documentChunks']
    flags: MaterialFlagsState['flags']
    toolObjects: ToolsState['toolObjects']
}

export const selectMarkupState = createSelector(
    (state: RootState): DocumentMarkupStoreState => ({
        activeFloor: selectDrawableActiveFloor(state),
        drawableLocations: getCurrentDrawableLocations(state.IMUP),
        openingGroups: selectDrawableGroupsGeometries(state),
        areaOpacity: state.IMUP.tools.areaOpacityValue,
        lineOpacity: state.IMUP.tools.lineOpacityValue,
        regions: state.IMUP.region.regions,
        documentChunks: state.IMUP.documents.documentChunks,
        flags: state.IMUP.materialFlags.flags,
        toolObjects: state.IMUP.tools.toolObjects,
    }),
    (state: DocumentMarkupStoreState): DocumentMarkupStoreState => state
)

type MarkupDocumentYield =
    | StrictEffect
    | IMUP2DDrawableLocation[]
    | number[]
    | Region[]
    | NormalizedFlag[]
    | AllEffect<CallEffect<void>[]>

type DocumentMarkupTools = [Color, Comment, Highlight, Image, PathTool, Workspace, RegionTool, Flag, Label, Select]

type MarkupDocumentNext = (PaperManager | null) &
    DocumentMarkupStoreState &
    DocumentMarkupTools &
    IMUP2DDrawableLocation[] &
    paper.Path[] &
    number[] &
    paper.Group[] &
    DocumentMapping[] &
    Region[] &
    FlagsState &
    NormalizedFlag[] &
    AIAutomatedObject[] & { isRegionLinesVisible: boolean; isMaterialFlagsVisible: boolean }

export function* createHighlightFromLocation(
    highlightTool: Highlight,
    colorTool: Color,
    location: IMUP2DDrawableLocation
) {
    const path: paper.Path = yield call(
        highlightTool.createHighlight,
        location.coordinates,
        colorTool.createColor(`#${location.settings.color}`)
    )

    path.data.drawable_id = location.drawable_id
    path.data.opening_group_id = location.opening_group_id
    path.data.opening_location_id = location.opening_location_id
    path.data.document_chunk_id = location.document_chunk_id

    yield call(highlightTool.roundPath, path, 16)
}

export function* markupDocument(): Generator<MarkupDocumentYield, void, MarkupDocumentNext> {
    try {
        // get the 2D drawing manager
        const manager: PaperManager | null = yield call(managers.get2DManager)

        // do nothing if the manager is null
        if (isNull(manager)) return

        // scene is rendering
        yield put(sceneRendering())

        // get markup state from redux store
        const {
            openingGroups,
            areaOpacity,
            lineOpacity,
            drawableLocations,
            activeFloor,
            regions,
            documentChunks,
            flags,
            toolObjects,
        }: DocumentMarkupStoreState = yield select(selectMarkupState)

        // get tools from manager
        const [
            colorTool,
            commentTool,
            highlightTool,
            imageTool,
            pathTool,
            workspaceTool,
            regionTool,
            flagTool,
            labelTool,
            selectTool,
        ]: DocumentMarkupTools = yield call(manager.getTools, [
            Color.NAME,
            Comment.NAME,
            Highlight.NAME,
            Image.NAME,
            PathTool.NAME,
            Workspace.NAME,
            RegionTool.NAME,
            Flag.NAME,
            Label.NAME,
            Select.NAME,
        ])

        if (isNull(activeFloor)) return

        // clear the workspace for a clean canvas
        yield call(workspaceTool.clear)

        // load the plan blueprint image for the active floor
        yield call(imageTool.insertImage, activeFloor.document_chunk.src)

        // Set the calibration ratio from the document chunk
        const xFactor = activeFloor.document_chunk.calibration_factor_x
        const yFactor = activeFloor.document_chunk.calibration_factor_y

        if (xFactor === yFactor) {
            yield put(setCalibrationRatio(xFactor))
        } else if (xFactor === 1 || yFactor === 1) {
            const calibrationFactor = xFactor === 1 ? yFactor : xFactor

            yield put(setCalibrationRatio(calibrationFactor))
        } else {
            const calibrationFactor = (xFactor + yFactor) / xFactor

            yield put(setCalibrationRatio(calibrationFactor))
        }

        // if there are regions, render the regions for the active floor
        const activeDocumentChunk = documentChunks?.find((chunk) => chunk.id === activeFloor.document_chunk.id) ?? null

        let regionPaths: paper.Path[] = []

        if (activeDocumentChunk) {
            const calibrationApplied =
                Boolean(activeDocumentChunk.calibration_factor_x && activeDocumentChunk.calibration_factor_x !== 1) ||
                Boolean(activeDocumentChunk.calibration_factor_y && activeDocumentChunk.calibration_factor_y !== 1)

            const localRegions: Region[] = yield regions.filter(
                (region) => region.document_chunk_id === activeDocumentChunk.id
            )

            if (localRegions.length) {
                const color = colorTool.createColor(REGION_COLOR)

                regionPaths = yield all(
                    localRegions.map((region) =>
                        call(
                            regionTool.renderRegion,
                            color,
                            region.coordinates,
                            region.id,
                            region.scale,
                            calibrationApplied
                        )
                    )
                )
            }

            const pageFlags: OpeningLinkWithFlagId[] = []

            flags.forEach((flag) => {
                flag.opening_links.forEach((link) => {
                    if (link.document_chunk_id === activeDocumentChunk.id) {
                        pageFlags.push({ ...link, flag_id: flag.id, order: flag.order, type: flag?.type?.name || null })
                    }
                })
            })

            // reset cached flags on page change
            yield call(flagTool.resetCachedFlags)

            if (!!pageFlags) {
                const flagGroups: paper.Group[] = yield all(
                    pageFlags.map((data) =>
                        call(
                            flagTool.drawFlagByCoords,
                            data.coordinates,
                            data.flag_id,
                            data.opening_id,
                            data.order,
                            data.type
                        )
                    )
                )
            }

            const pageToolObjects = toolObjects.filter(
                (toolObject) => toolObject.document_chunk_id === activeDocumentChunk.id
            )

            if (!!pageToolObjects.length) {
                yield all(
                    pageToolObjects.map((toolObj) =>
                        call(drawToolByType, toolObj, colorTool, pathTool, workspaceTool, lineOpacity, labelTool)
                    )
                )
            }
        }

        const criteriaRegionPaths = workspaceTool.getItemsWithCriteria(
            'data',
            (data) => data.shapeType === REGION_ENUMS.TYPE
        ) as paper.Path[]

        /**
         * filter drawables belonging to active floor plan,
         * sort by drawing sequence order: area -> section -> point,
         * and create paths
         */
        const paths: paper.Path[] = yield all(
            drawableLocations
                .filter(
                    ({ document_chunk_id, drawing_type }) =>
                        document_chunk_id === activeFloor.document_chunk.id &&
                        drawing_type !== DRAWABLE_TYPES.NOTE &&
                        drawing_type !== DRAWABLE_TYPES.HIGHLIGHTER
                )
                // sorting locations that AREAS will be drawn first
                .sort((a, b) => {
                    if (a.shapeType === DRAWING_TYPES.AREA && b.shapeType !== DRAWING_TYPES.AREA) {
                        return -1
                    }
                    if (a.shapeType !== DRAWING_TYPES.AREA && b.shapeType === DRAWING_TYPES.AREA) {
                        return 1
                    }

                    return 0
                })
                .map((location) => call(drawShapeByType, location, areaOpacity, lineOpacity, criteriaRegionPaths))
        )

        // Associate drawables with regions if there are regions on this page
        if (activeDocumentChunk) {
            const mappings: DocumentMapping[] = yield select(selectDocumentMappings)
            const currentDocumentMapping = mappings?.find((m) => m.document_chunk_id === activeDocumentChunk.id)

            for (const drawablePath of paths) {
                const item = workspaceTool.getItemWithPaperId(drawablePath.id) as paper.Path

                if (item) {
                    item.data.scale = currentDocumentMapping?.scale_factor ?? ''

                    for (const path of regionPaths) {
                        if (path && regionTool.isItemInsideRegion(item, path)) {
                            item.data.scale = path.data.scale
                        }
                    }
                }
            }

            // Set the scale based on the document mapping
            yield put(
                updateScaleFactor({
                    scale_factor: convertScaleFactorLabelEnumToDecimal(currentDocumentMapping?.scale_factor ?? ''),
                })
            )
        }

        // determine ids of hidden drawables groups
        const hiddenDrawableGroupIds: Set<number> = openingGroups.reduce((groupIds, { id, isActive }) => {
            if (Object.values(isActive).includes(false)) {
                groupIds.add(id)
            }

            return groupIds
        }, new Set<number>())

        // build visibility predicate with hidden drawable group ids
        const visibilityTest = (item: paper.Item): boolean =>
            !(hiddenDrawableGroupIds.has(item.data.opening_group_id) || isNonAreaJoistLine(item))

        // hide drawable groups per visibility test predicate
        yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)

        // attach tooltips to paths
        yield all(paths.map((path) => call(addTooltipsToPath, path, workspaceTool)))

        // Hide all drawables that are inactive by default
        yield fork(handleToggleDrawableGroups2D)

        // get comments ("NOTE" type drawables)
        const comments: IMUP2DDrawableLocation[] = yield drawableLocations.filter(
            ({ drawing_type, document_chunk_id }) =>
                isEqual(drawing_type, DRAWABLE_TYPES.NOTE) && isEqual(document_chunk_id, activeFloor.document_chunk.id)
        )

        // create comment pins
        yield all(
            comments.map(({ coordinates: [coordinate], settings: { text } }) =>
                call(commentTool.createComment, coordinate, text)
            )
        )

        const highlightLayers: IMUP2DDrawableLocation[] = yield drawableLocations.filter(
            ({ drawing_type, document_chunk_id }) =>
                drawing_type === DRAWABLE_TYPES.HIGHLIGHTER && document_chunk_id === activeFloor.document_chunk.id
        )

        yield all(
            highlightLayers.reduce((highlightEffects, location) => {
                return !location.coordinates || !location.settings.color
                    ? highlightEffects
                    : [...highlightEffects, call(createHighlightFromLocation, highlightTool, colorTool, location)]
            }, [] as CallEffect[])
        )

        // handle AI drawables suggestions
        yield put(drawAISuggestions())

        // activate the select tool for the user
        yield call(manager.useTool, Select.NAME)

        // get the visibility for region lines and material flags
        const { isRegionLinesVisible, isMaterialFlagsVisible } = yield select(selectVisibility)

        // set visibility for region lines
        yield call(handleToggleRegionLinesVisibility, {
            payload: isRegionLinesVisible,
            type: toggleRegionLinesVisibilityAction.type,
        })

        // set visibility for material flags
        yield call(handleToggleMaterialFlagsVisibility, {
            payload: isMaterialFlagsVisible,
            type: toggleMaterialFlagsVisibilityAction.type,
        })

        yield call(selectTool.setMultiSelectionMode.bind(selectTool), createMultiSelectPredicates())

        // the scene is ready
        yield put(sceneRendered())
    } catch (error) {
        console.error(error)
        yield call(console.error, error as any)
    }
}

export function* watchForDocumentMarkup(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(draw2DMarkup.type, markupDocument)
}
