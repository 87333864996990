import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    ConfigurationQuestionAndAnswersResponse,
    CornersMeshInputData,
    IBabylonLineWithMetadata,
    IBabylonPolygonContourWithMetadata,
    IGeometry,
    IModel,
    IModelTranslationInfo,
    IVector,
} from '../types'
import { FETCH_PROJECT_DATA_FROM_PORTAL } from '../../actions/drawable'
import { RootState } from '../../stores'

export type State3D = {
    selectedMeshID: string | null
    selectedMeshLabelID: string | null
    geometries: Record<string, IGeometry[]> | null
    structure: IModel | null
    errors: string[]
    selectedOpeningGroupID: number | null
    roofFacesData: Record<string, IBabylonPolygonContourWithMetadata> | null
    overFramedRoofFacesData: Record<string, IBabylonPolygonContourWithMetadata> | null
    cornersData: Record<string, CornersMeshInputData> | null
    roofSingleEdgesData: Record<string, IBabylonLineWithMetadata> | null
    junctionsData: Record<string, IBabylonLineWithMetadata> | null
    openingsToMeshIdMap: Record<string, string> | null
    mousePosition: { x: number; y: number } | null
    joistLinesData: Record<string, IBabylonLineWithMetadata> | null
    initialTranslationToCenter: IModelTranslationInfo | null
    shouldOpenMeshOpeningGroupMenu: boolean
    shouldResetCameraSelection: boolean
    measurementPoints: IVector[] | null
    visibleFloor: string | null
    selectedDesignOptionId: string | null | undefined
    configurationQuestionAndAnswerData: ConfigurationQuestionAndAnswersResponse | null
    visibleDrawableGroupIds: number[]
    hiddenDrawableGroupIds: number[]
}

export const initial3DState: State3D = {
    selectedMeshID: null,
    selectedMeshLabelID: null,
    selectedOpeningGroupID: null,
    geometries: null,
    structure: null,
    roofFacesData: null,
    overFramedRoofFacesData: null,
    cornersData: null,
    roofSingleEdgesData: null,
    junctionsData: null,
    openingsToMeshIdMap: null,
    mousePosition: null,
    shouldOpenMeshOpeningGroupMenu: false,
    joistLinesData: null,
    initialTranslationToCenter: null,
    shouldResetCameraSelection: false,
    measurementPoints: null,
    errors: [],
    visibleFloor: null,
    selectedDesignOptionId: undefined,
    configurationQuestionAndAnswerData: null,
    visibleDrawableGroupIds: [],
    hiddenDrawableGroupIds: [],
}

function handleSelectedMesh(state: State3D, { payload }: PayloadAction<{ id: string | null }>): void {
    state.selectedMeshID = payload.id
}

function handleSelectedMeshLabelId(state: State3D, { payload }: PayloadAction<{ id: string | null }>): void {
    state.selectedMeshLabelID = payload.id
}

function handleUpdateJoistLinesData(
    state: State3D,
    { payload }: PayloadAction<Record<string, IBabylonLineWithMetadata> | null>
) {
    state.joistLinesData = payload
}

function handleTranslationObject(state: State3D, { payload }: PayloadAction<IModelTranslationInfo | null>) {
    state.initialTranslationToCenter = payload
}

function handleUpdatingSelectedOpeningGroup(state: State3D, { payload }: PayloadAction<{ id: number | null }>): void {
    state.selectedOpeningGroupID = payload.id
}

function handleUpdateStructure(state: State3D, action: PayloadAction<IModel | null>): void {
    state.structure = action.payload
}

function handleupdateConfigurationQuestionAndAnswersData(
    state: State3D,
    action: PayloadAction<ConfigurationQuestionAndAnswersResponse | null>
): void {
    state.configurationQuestionAndAnswerData = action.payload
}

function handleUpdateCornersData(
    state: State3D,
    { payload }: PayloadAction<Record<string, CornersMeshInputData> | null>
) {
    state.cornersData = payload
}

function handleUpdateRoofSingleEdgesData(
    state: State3D,
    { payload }: PayloadAction<Record<string, IBabylonLineWithMetadata> | null>
) {
    state.roofSingleEdgesData = payload
}

function handleUpdateJunctionsData(
    state: State3D,
    { payload }: PayloadAction<Record<string, IBabylonLineWithMetadata> | null>
) {
    state.junctionsData = payload
}

function handleUpdateRoofFacesData(
    state: State3D,
    { payload }: PayloadAction<Record<string, IBabylonPolygonContourWithMetadata> | null>
): void {
    state.roofFacesData = payload
}

function handleUpdateOverFramedRoofFacesData(
    state: State3D,
    { payload }: PayloadAction<Record<string, IBabylonPolygonContourWithMetadata> | null>
): void {
    state.overFramedRoofFacesData = payload
}

function handleUpdateOpeningsToMesIDMap(state: State3D, { payload }: PayloadAction<Record<string, string> | null>) {
    state.openingsToMeshIdMap = payload
}

function handleUpdateStructureGeometries(
    state: State3D,
    { payload }: PayloadAction<{ [key: string]: IGeometry[] } | null>
): void {
    state.geometries = payload
}

function handleMousePositionUpdate(state: State3D, { payload }: PayloadAction<{ x: number; y: number }>) {
    state.mousePosition = payload
}

function handleUpdateShouldOpenMeshOpeningGroupMenu(state: State3D, { payload }: PayloadAction<boolean>) {
    state.shouldOpenMeshOpeningGroupMenu = payload
}

function handleFetchModelStructureFailure(state: State3D, action: PayloadAction<string>): void {
    state.errors.push(action.payload)
}

function handleUpdateCameraResetFlag(state: State3D, action: PayloadAction<boolean>): void {
    state.shouldResetCameraSelection = action.payload
}

function handleUpdateMeasurementPoints(state: State3D, action: PayloadAction<IVector[] | null>): void {
    state.measurementPoints = action.payload
}

function handleResettingErrors(state: State3D): void {
    state.errors = []
}

function handleUpdateVisibleFloor(state: State3D, action: PayloadAction<string | null>): void {
    state.visibleFloor = action.payload
}

function handleUpdateDesignOptionId(state: State3D, action: PayloadAction<string | null | undefined>): void {
    state.selectedDesignOptionId = action.payload
}

function handleSetVisibleAndHiddenDrawableGroupIds(
    state: State3D,
    action: PayloadAction<{ visibleDrawableGroupIds: number[]; hiddenDrawableGroupIds: number[] }>
): void {
    const { visibleDrawableGroupIds, hiddenDrawableGroupIds } = action.payload

    state.visibleDrawableGroupIds = visibleDrawableGroupIds
    state.hiddenDrawableGroupIds = hiddenDrawableGroupIds
}

const reducers = {
    selectMesh: handleSelectedMesh,
    selectMeshLabelId: handleSelectedMeshLabelId,
    updateStructure: handleUpdateStructure,
    updateStructureGeometries: handleUpdateStructureGeometries,
    fetchModelStructureFailure: handleFetchModelStructureFailure,
    resetErrors: handleResettingErrors,
    updateSelectedOpeningGroup: handleUpdatingSelectedOpeningGroup,
    updateRoofFaces: handleUpdateRoofFacesData,
    updateOverFramedRoofFaces: handleUpdateOverFramedRoofFacesData,
    updateCorners: handleUpdateCornersData,
    updateRoofEdges: handleUpdateRoofSingleEdgesData,
    updateJunctions: handleUpdateJunctionsData,
    updateOpeningsToMeshIdMap: handleUpdateOpeningsToMesIDMap,
    updateMousePosition: handleMousePositionUpdate,
    updateShouldOpenMeshOpeningGroupMenu: handleUpdateShouldOpenMeshOpeningGroupMenu,
    updateJoistLines: handleUpdateJoistLinesData,
    updateTranslationInfo: handleTranslationObject,
    updateCameraResetFlag: handleUpdateCameraResetFlag,
    updateMeasurementPoints: handleUpdateMeasurementPoints,
    updateVisibleFloor: handleUpdateVisibleFloor,
    selectDesignOption: handleUpdateDesignOptionId,
    updateConfigurationQuestionAndAnswersData: handleupdateConfigurationQuestionAndAnswersData,
    setVisibleAndHiddenDrawableGroupIds: handleSetVisibleAndHiddenDrawableGroupIds,
}

export const fetchProjectData = createAction(FETCH_PROJECT_DATA_FROM_PORTAL)

const slice3D = createSlice({
    name: '3D',
    initialState: initial3DState,
    reducers,
})

export const projectNumberSelector = createSelector(
    (state: RootState) => {
        return state.drawable.project.portal_number
    },
    (projectID) => projectID
)
export const currentModelIdSelector = createSelector(
    (state: RootState) => {
        return state.drawable.project.currentModelId
    },
    (projectID) => projectID
)
export const selectedOpeningGroupIdSelector = createSelector(
    (state: RootState) => {
        return state.IMUP['3D'].selectedOpeningGroupID
    },
    (id) => id
)
export const openingToMeshIdMappingSelector = createSelector(
    (state: RootState) => {
        return state.IMUP['3D'].openingsToMeshIdMap
    },
    (map) => map
)

export const geometriesSelector = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ geometries }) => ({
        geometries,
    })
)

export const joistLinesDataSelector = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ joistLinesData }) => joistLinesData
)

export const translationInfoSelector = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ initialTranslationToCenter }) => initialTranslationToCenter
)

export const visibleFloorSelector = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ visibleFloor }) => visibleFloor
)

export const designOptionIdSelected = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ selectedDesignOptionId }) => selectedDesignOptionId
)

export const configurationQuestionAndAnswersDataSelector = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ configurationQuestionAndAnswerData }) => configurationQuestionAndAnswerData
)

export const selectVisibleAndHiddenDrawableGroupIds = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({ visibleDrawableGroupIds, hiddenDrawableGroupIds }) => ({
        visibleDrawableGroupIds,
        hiddenDrawableGroupIds,
    })
)

export const {
    selectMesh,
    selectMeshLabelId,
    updateStructure,
    updateStructureGeometries,
    fetchModelStructureFailure,
    resetErrors,
    updateSelectedOpeningGroup,
    updateRoofFaces,
    updateOverFramedRoofFaces,
    updateCorners,
    updateRoofEdges,
    updateJunctions,
    updateOpeningsToMeshIdMap,
    updateMousePosition,
    updateShouldOpenMeshOpeningGroupMenu,
    updateJoistLines,
    updateTranslationInfo,
    updateCameraResetFlag,
    updateMeasurementPoints,
    updateVisibleFloor,
    selectDesignOption,
    updateConfigurationQuestionAndAnswersData,
    setVisibleAndHiddenDrawableGroupIds,
} = slice3D.actions

export default slice3D
