import { PathTool } from '../path/Path.tool'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { Cursors, MouseButtonCodes, PaperToolConfig } from '../../../../../types'
import { SNAPPING_TOLERANCE } from '../../../../../utils/constants'
import { getSnapPoint, snap } from '../../../../utils/Snapping'

/**
 * Pen Tool
 * A tool used to draw an arbitrary area
 */
export class Pen extends PathTool {
    private static COMPLETE_CREATION_KEY = 'enter'
    private path: paper.Path | null = null
    private temporaryLine: paper.Path | null = null
    private temporaryAreaPointPaths: paper.Group[] = []
    private strokeColor: paper.Color = new this.paper.Color('blue')
    private opacity: ToolsState['areaOpacityValue'] = initialToolsState.areaOpacityValue

    static NAME = 'PEN'

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Pen.NAME
    }

    /**
     * on cancel, if there is
     * any stray single points
     * clear those points
     */
    cancel = (): void => {
        this.path?.remove()
        this.path = null
        this.cleanupPointsAndPaths()
    }

    /**
     * Delete temporary guide line and old points
     * in the path
     */
    private cleanupPointsAndPaths = (): void => {
        this.temporaryLine?.remove()
        this.temporaryLine = null
        this.temporaryAreaPointPaths.forEach((point) => point.remove())
        this.temporaryAreaPointPaths = []
    }

    private undoLastPoint = (): void => {
        if (this.path && this.temporaryAreaPointPaths && this.path.segments.length > 1) {
            this.temporaryLine?.removeSegment(this.temporaryLine.segments.length - 1)

            this.temporaryAreaPointPaths.pop()!.remove()

            this.path.removeSegment(this.path.segments.length - 1)
        }
    }

    /**
     * On mouse move, draw a temporary line from the last
     * segment point
     * @param event
     */
    public onMouseMove = (event: paper.ToolEvent): void => {
        const isShiftKeyPressed = event['event'].shiftKey

        if (this.path) {
            const startingPoint = this.path.lastSegment.point
            // remove the previous line that is no longer relevant

            this.temporaryLine?.remove() // remove the temporary drawing path

            const snappingItems = this.getSnappingItems()

            // get the point when snapping is enabled
            const point = this.snappingEnabled ? getSnapPoint(snappingItems, event.point) : event.point

            const newPoint = isShiftKeyPressed ? this.calculateFixedAnglePoint(startingPoint, point) : point

            this.temporaryLine = new this.paper.Path([startingPoint, newPoint])
            this.temporaryLine.strokeColor = this.strokeColor
            this.temporaryLine.strokeWidth = this.strokeWidth
        }
    }

    onMouseDrag = (event: paper.ToolEvent): void => {
        this.toolPanning(event)
    }

    onMouseUp = (): void => {
        this.setState('common', { cursor: Cursors.AUTO }) // resets the cursor in case panning was done
    }

    /**
     * On mouse down do one of:
     * 1- Bail if the mouse event is not a left click
     * 2- If this is the first point ot be created
     * create a new black path and add that point to it
     * 3- If there is already a path, then determine the
     * nearest 45 degree angle to the mouse position
     * 4- If the last drawn segment point is clicked
     * then commit the path and queue the task to create the drawable
     * @param event the tool mouse event
     */
    onMouseDown = (event: paper.ToolEvent): void => {
        const isShiftKeyPressed = event['event'].shiftKey
        // indicate pan is active on right click
        const clickCode = event['event']['button']

        if (clickCode !== MouseButtonCodes.Left) return

        this.setSnappingItems()

        let newPoint = event.point

        if (this.path !== null && isShiftKeyPressed) {
            newPoint = this.calculateFixedAnglePoint(this.path.lastSegment.point, event.point)
        }

        if (this.snappingEnabled) {
            const snapPoint = snap(this.paper, newPoint, { tolerance: SNAPPING_TOLERANCE })

            if (snapPoint) {
                newPoint = snapPoint
            }
        }

        if (this.path === null) {
            this.path = new this.paper.Path()

            this.path.strokeColor = this.strokeColor
            this.path.fillColor = this.strokeColor
            this.path.data.shapeType = DRAWING_TYPES.AREA
            this.path.closed = true
            this.path.strokeWidth = this.strokeWidth
            this.path.opacity = this.opacity

            this.setScaleFromPointClick(newPoint)
        } else if (this.path.length > 2) {
            // remove the stroke width after the area starts to appear
            this.path.strokeWidth = 0
        }

        this.path.add(newPoint)
        this.temporaryAreaPointPaths.push(this.renderPoint(newPoint))
    }

    onKeyUp = (keyEvent) => {
        this.activateBaseOnKeyUpActions(keyEvent)

        if (['delete', 'backspace'].includes(keyEvent.key)) this.undoLastPoint()

        if (keyEvent.key === Pen.COMPLETE_CREATION_KEY) {
            if (this.path && this.path.segments.length > 2) {
                this.setState('2D', { drawablesToCreate: [this.path.id] })
                this.cleanupPointsAndPaths()
                this.path = null
            }
        }
    }
}

export default Pen
